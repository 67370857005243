<template>
  <div>
    <div class="search">
      <div class="title">奖品列表</div>
      <hr />
      <el-form :inline="true" class="top">
        <el-form-item label="奖励标题:">
          <el-input v-model="params.title"></el-input>
        </el-form-item>
        <!-- <el-form-item label="手机号:">
          <el-input v-model="params.phone"></el-input>
        </el-form-item>
        <el-form-item label="时间:">
          <el-date-picker
            :editable="false"
            :picker-options="pickerOptions0"
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="date"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item> -->
        <el-form-item>
          <el-button type="primary" @click="getList(1)">查询</el-button>
          <el-button type="primary" @click="$router.push({path:'/prizeListAdd',query:{type}})"
            >添加</el-button
          >
          <!-- <el-button type="primary" @click="reset">重置</el-button> -->
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      border
      tooltip-effect="dark"
      :row-key="rowKey"
      style="width: 100%"
      align="center"
    >
      <!-- <el-table-column type="selection" width="55" :reserve-selection="true" align="center"> </el-table-column> -->
      <el-table-column prop="goalId" width="50" align="center" label="ID">
      </el-table-column>
      <el-table-column prop="title" width="180" label="奖励标题" align="center">
      </el-table-column>

      <el-table-column
        prop="orderNum"
        label="订单数量"
        width="200"
        align="center"
        v-if="type!='consumption'"
      >
      </el-table-column>
      <el-table-column
        prop="fee"
        label="消费金额"
        width="200"
        align="center"
        v-if="type=='consumption'"
      >
      </el-table-column>
      <el-table-column
        prop="strType"
        label="奖励类型"
        width="180"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="prodName"
        label="奖品名称"
        width="300"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="count" label="奖品数量" width="200" align="center">
      </el-table-column>
      <el-table-column
        prop="addTime"
        label="注册时间"
        width="200"
        align="center"
      >
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <template v-if="type=='order'">
          <el-button
            type="primary"
            size="mini"
            @click="edit(scope.row)"
            v-if="
              $store.state.powerList.indexOf('invite:order:goal:edit') !== -1
            "
            >编辑</el-button
          >
          <el-button
            type="primary"
            size="mini"
            @click="remove(scope.row)"
            v-if="
              $store.state.powerList.indexOf('invite:order:goal:delete') !== -1
            "
            >删除</el-button
          >
        </template>
        <template v-if="type=='register'">
          <el-button
            type="primary"
            size="mini"
            @click="edit(scope.row)"
            v-if="
              $store.state.powerList.indexOf('invite:user:goal:edit') !== -1
            "
            >编辑</el-button
          >
          <el-button
            type="primary"
            size="mini"
            @click="remove(scope.row)"
            v-if="
              $store.state.powerList.indexOf('invite:user:goal:delete') !== -1
            "
            >删除</el-button
          >
        </template>
        <template v-if="type=='consumption'">
          <el-button
            type="primary"
            size="mini"
            @click="edit(scope.row)"
            v-if="
              $store.state.powerList.indexOf('invite:fee:goal:edit') !== -1
            "
            >编辑</el-button
          >
          <el-button
            type="primary"
            size="mini"
            @click="remove(scope.row)"
            v-if="
              $store.state.powerList.indexOf('invite:fee:goal:delete') !== -1
            "
            >删除</el-button
          >
        </template>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <!-- <div>
              <el-button type="primary" @click="allCheckVip">批量修改vip</el-button>
            <el-button type="primary" @click="allOpe">批量操作</el-button>
         </div> -->
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.currentPage"
          :page-sizes="[5, 10, 20]"
          :page-size="params.pageSize"
          layout="total,sizes,prev,pager,next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
    >
      <el-form :model="addList" :rules="addRules" ref="addRuleFormRef">
        <el-form-item label="分类名称:">
          <el-input v-model="addList.cateName"></el-input>
        </el-form-item>
        <el-form-item label="分类图片:">
          <el-input v-model="addList.cateName"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  prize,
  prizeDel,
  prizeRegister,
  prizeDelRegister,
  prizeConsumption,
  prizeDelConsumption
} from "../../api/invite.js";
export default {
  data() {
    return {
      dialogVisible: false,
      total: 0,
      params: {
        currentPage: 1,
        pageSize: 5,
        title: "",
        // phone: "",
        // beginTime: "",
        // endTime: "",
      },
      addList: {
        cateId: 0,
        cateName: "",
        cateImg: "",
      },
      addRules: {
        tableData: [
          {
            required: true,
            message: "请输入活动名称",
            trigger: ["blur", "change"],
          },
        ],
      },
      tableData: [],
      date: null,
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        },
      },
      type: "",
    };
  },
  created() { 
    this.type = this.$route.query.type;
    this.getList();
  },
  watch: {
    $route(to, from) {
    this.type = this.$route.query.type;
    this.params.currentPage = 1;
      this.params.pageSize = 5;
      this.params.title = '';
      this.getList();
    },
  },
  methods: {
    remove(row) {
      this.$confirm("此操作将删除该奖励, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let demaad = this.type == "order" ? prizeDel : this.type == "register"? prizeDelRegister:prizeDelConsumption;
          const { data } = await demaad({ goalId: row.goalId });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getList();
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    edit(row) {
      this.$router.push({
        path: "/prizeListAdd",
        query: {
          id: row.goalId,
          type:this.type
        },
      });
    },
    rowKey(row) {
      return row.userId;
    },
    async getList(page = "") {
      if (page) {
        this.params.currentPage = page;
      }
      //   this.params.beginTime = this.date ? this.date[0] : "";
      //   this.params.endTime = this.date ? this.date[1] : "";
      let demaad = this.type == "order" ? prize :this.type == "register"? prizeRegister : prizeConsumption;

      const { data } = await demaad(this.params);
      if (data.code == 0) {
        this.tableData = data.list;
        this.total = data.pagination.total;
      } else {
      }
    },
    handleSizeChange(e) {
      this.params.pageSize = e;
      this.getList();
    },
    // 页数改变
    handleCurrentChange(e) {
      this.params.currentPage = e;
      this.getList();
    },
    reset() {
      this.params.currentPage = 1;
      this.params.pageSize = 5;
      this.params.phone = "";
      this.params.beginTime = "";
      this.params.endTime = "";
      this.date = null;
      this.getList();
    },
  },
};
</script>

<style scoped>
.title {
  padding: 10px 0;
}
.top {
  margin-top: 20px;
}
.block {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
</style>
