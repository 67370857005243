var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"search"},[_c('div',{staticClass:"title"},[_vm._v("奖品列表")]),_c('hr'),_c('el-form',{staticClass:"top",attrs:{"inline":true}},[_c('el-form-item',{attrs:{"label":"奖励标题:"}},[_c('el-input',{model:{value:(_vm.params.title),callback:function ($$v) {_vm.$set(_vm.params, "title", $$v)},expression:"params.title"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.getList(1)}}},[_vm._v("查询")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({path:'/prizeListAdd',query:{type: _vm.type}})}}},[_vm._v("添加")])],1)],1)],1),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"border":"","tooltip-effect":"dark","row-key":_vm.rowKey,"align":"center"}},[_c('el-table-column',{attrs:{"prop":"goalId","width":"50","align":"center","label":"ID"}}),_c('el-table-column',{attrs:{"prop":"title","width":"180","label":"奖励标题","align":"center"}}),(_vm.type!='consumption')?_c('el-table-column',{attrs:{"prop":"orderNum","label":"订单数量","width":"200","align":"center"}}):_vm._e(),(_vm.type=='consumption')?_c('el-table-column',{attrs:{"prop":"fee","label":"消费金额","width":"200","align":"center"}}):_vm._e(),_c('el-table-column',{attrs:{"prop":"strType","label":"奖励类型","width":"180","align":"center"}}),_c('el-table-column',{attrs:{"prop":"prodName","label":"奖品名称","width":"300","align":"center"}}),_c('el-table-column',{attrs:{"prop":"count","label":"奖品数量","width":"200","align":"center"}}),_c('el-table-column',{attrs:{"prop":"addTime","label":"注册时间","width":"200","align":"center"}}),_c('el-table-column',{attrs:{"label":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.type=='order')?[(
            _vm.$store.state.powerList.indexOf('invite:order:goal:edit') !== -1
          )?_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.edit(scope.row)}}},[_vm._v("编辑")]):_vm._e(),(
            _vm.$store.state.powerList.indexOf('invite:order:goal:delete') !== -1
          )?_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.remove(scope.row)}}},[_vm._v("删除")]):_vm._e()]:_vm._e(),(_vm.type=='register')?[(
            _vm.$store.state.powerList.indexOf('invite:user:goal:edit') !== -1
          )?_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.edit(scope.row)}}},[_vm._v("编辑")]):_vm._e(),(
            _vm.$store.state.powerList.indexOf('invite:user:goal:delete') !== -1
          )?_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.remove(scope.row)}}},[_vm._v("删除")]):_vm._e()]:_vm._e(),(_vm.type=='consumption')?[(
            _vm.$store.state.powerList.indexOf('invite:fee:goal:edit') !== -1
          )?_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.edit(scope.row)}}},[_vm._v("编辑")]):_vm._e(),(
            _vm.$store.state.powerList.indexOf('invite:fee:goal:delete') !== -1
          )?_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.remove(scope.row)}}},[_vm._v("删除")]):_vm._e()]:_vm._e()]}}])})],1),_c('div',{staticClass:"block"},[_c('div',[_c('el-pagination',{attrs:{"current-page":_vm.params.currentPage,"page-sizes":[5, 10, 20],"page-size":_vm.params.pageSize,"layout":"total,sizes,prev,pager,next, jumper","total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)]),_c('el-dialog',{attrs:{"title":"提示","visible":_vm.dialogVisible,"width":"30%","close-on-click-modal":false},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('el-form',{ref:"addRuleFormRef",attrs:{"model":_vm.addList,"rules":_vm.addRules}},[_c('el-form-item',{attrs:{"label":"分类名称:"}},[_c('el-input',{model:{value:(_vm.addList.cateName),callback:function ($$v) {_vm.$set(_vm.addList, "cateName", $$v)},expression:"addList.cateName"}})],1),_c('el-form-item',{attrs:{"label":"分类图片:"}},[_c('el-input',{model:{value:(_vm.addList.cateName),callback:function ($$v) {_vm.$set(_vm.addList, "cateName", $$v)},expression:"addList.cateName"}})],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("确 定")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }